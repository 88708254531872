import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL + "api/";
	
	private mailCountChange = new Subject<any>();
	private mailOnlyCountChange = new Subject<any>();
	private notifiViewChange = new Subject<any>();
	private sessionExpMsg = new Subject<any>();
	
	constructor(private httpClient: HttpClient,private router: Router) { }

	getHeaders1(){
		let token:any = localStorage.getItem('tokenKey');
		return new HttpHeaders().append("Authorization",token).append("Content-Type",'application/json').append("Accept",'application/json');
	}

	getHeaders2(){
		return new HttpHeaders().append("Content-Type",'application/json').append("Accept",'application/json');
	}

	getHeaders3(){
		let token:any = localStorage.getItem('tokenKey');
		return new HttpHeaders().append("Authorization",token);
	}

	getHeaders4(){
		let token:any = localStorage.getItem('tokenKey');
		return new HttpHeaders().append("Authorization",token).append("Content-Type",'application/json');
	}

	getHeaders5(){
		let token:any = localStorage.getItem('tokenKey');
		return new HttpHeaders().append("Authorization",token).append("Accept",'application/json');
	}
	
	sendCountChangeEvent() {
		this.mailCountChange.next(null);
	}
	getCountChangeEvent(): Observable<any> {
		return this.mailCountChange.asObservable();
	}
	
	sendOnlyCountChangeEvent() {
		this.mailOnlyCountChange.next(null);
	}
	getOnlyCountChangeEvent(): Observable<any> {
		return this.mailOnlyCountChange.asObservable();
	}
	
	sendNotiViewEvent() {
		this.notifiViewChange.next(null);
	}
	getNotiViewEvent(): Observable<any> {
		return this.notifiViewChange.asObservable();
	}
	
	sendSessionExpMessage() {
		this.sessionExpMsg.next(null);
	}
	getSessionExpMessage(): Observable<any> {
		return this.sessionExpMsg.asObservable();
	}
	
	public loginAPI(user:any) {
		var data = { email: user.email, password: user.password }
		return this.httpClient.post(this.baseURL + "login", data);
	}
	
	public passwordReset(email:any) {
		var data = { email: email }
		return this.httpClient.post(this.baseURL + "passwordReset", data);
	}
	
	public newPassword(data:any) {
		return this.httpClient.post(this.baseURL + "newPassword", data);
	}
	
	public getMailBoxCount() {
		return this.httpClient.get(this.baseURL + "mailBoxList", { headers: this.getHeaders1() });
	}
	
	public listMailFolders(page:any, limit:any, search:any) {
		const params: any = { page: page, limit: limit, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "listMailFolders", options);
	}
	
	public fetchfolderCount(folderName:any) {
		const params: any = { folderName: folderName };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchfolderCount", options);
	}
	
	public fetchLocalEmails(category:any, page:any, limit:any, search:any) {
		const params: any = { page: page, limit: limit, category: category, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchLocalEmails", options);
	}
	
	public flagList() {
		return this.httpClient.get(this.baseURL + "flagList", { headers: this.getHeaders1() });
	}
	
	public assignFlag(mId:any, flag:any) {
		var data = { mailId: mId, flag: flag }
		return this.httpClient.post(this.baseURL + "assignFlag", data, { headers: this.getHeaders1() });
	}
	
	public assignFlagToEvent(eventId:any, flag:any) {
		var data = { eventId: eventId, flag: flag }
		return this.httpClient.post(this.baseURL + "assignEventFlag", data, { headers: this.getHeaders1() });
	}
	
	public moveMailListtoFolder(mIdList:any, id:any) {
		var data = { mailIdList: mIdList, mailId: null, id: id }
		return this.httpClient.post(this.baseURL + "moveMailtoFolder", data, { headers: this.getHeaders1() });
	}
	
	public moveMailListtoInbox(mIdList:any) {
		var data = { mailIdList: mIdList, mailId: null }
		return this.httpClient.post(this.baseURL + "moveMailtoInbox", data, { headers: this.getHeaders1() });
	}
	
	public markListSpam(mId:any) {
		var data = { mailIdList: mId, mailId: null }
		return this.httpClient.post(this.baseURL + "assignAsSpam", data, { headers: this.getHeaders1() });
	}
	
	public mailStatusSet(mId:any) {
		var data = { mailId: mId }
		return this.httpClient.post(this.baseURL + "mailStatusSet", data, { headers: this.getHeaders1() });
	}
	
	public fetchReplyEmails(emailtext:any) {
		const params: any = { emailtext: emailtext };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchReplyEmails", options);
	}
	
	public saveAsDraft(data:any) {
		return this.httpClient.post(this.baseURL + "saveAsDraft", data, { headers: this.getHeaders1() });
	}
	
	public composeMail(data:any) {
		return this.httpClient.post(this.baseURL + "composeMail", data, { headers: this.getHeaders1() });
	}
	
	public updateDraft(data:any) {
		return this.httpClient.post(this.baseURL + "updateDraft", data, { headers: this.getHeaders1() });
	}
	
	public sendDraft(data:any) {
		return this.httpClient.post(this.baseURL + "sendDraft", data, { headers: this.getHeaders1() });
	}
	
	public deleteDraft(id:any) {
		var data = { id: id };
		return this.httpClient.post(this.baseURL + "deleteDraft", data, { headers: this.getHeaders1() });
	}
	
	public mailReplyMessage(data:any) {
		return this.httpClient.post(this.baseURL + "mailReplyMessage", data, { headers: this.getHeaders1() });
	}
	
	public findMailIdDetails(id:any) {
		const params: any = { id: id };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "findMailIdDetails", options);
	}
	
	public fetchVessels() {
		return this.httpClient.get(this.baseURL + "fetchVessels", { headers: this.getHeaders1() });
	}
	
	public allEventCount() {
		return this.httpClient.get(this.baseURL + "allEventCount", { headers: this.getHeaders1() });
	}
	
	public eventList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "eventList", options);
	}
	
	public companyList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "companyList", options);
	}
	
	
	public companyNameValidation(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "companyNameValidation", options);
	}
	
	public companyEmailExist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "companyEmailExist", options);
	}
	
	public countryCodes() {
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: this.getHeaders2() });
	}
	
	public medicineMasterList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "medicineMasterList", options);
	}
	
	public companyDetails(data:any) {
		return this.httpClient.post(this.baseURL + "companyDetails", data, { headers: this.getHeaders1() });
	}
	
	public getCompanyDetails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "companyDetails", options);
	}
	
	public updateCompany(data:any) {
		return this.httpClient.post(this.baseURL + "updateCompany", data, { headers: this.getHeaders1() });
	}
	
	public removeCompany(companyId:any) {
		var data = {
			companyId: companyId
		}
		return this.httpClient.post(this.baseURL + "removeCompany", data, { headers: this.getHeaders1() });
	}
	
	public amoscodeList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "amoscodeList", options);
	}
	
	
	public vesselList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "vesselList", options);
	}
	
	public removeVessel(vesselId:any) {
		var data = {
			vesselId: vesselId
		}
		return this.httpClient.post(this.baseURL + "removeVessel", data, { headers: this.getHeaders1() });
	}
	
	public vesselNote(note:any, vesselId:any) {
		var data = { "note": note, "vesselId": vesselId }
		return this.httpClient.post(this.baseURL + "vesselNote", data, { headers: this.getHeaders1() });
	}
	
	public resetPassword(password:any, vesselId:any, vesselUserId:any) {
		var data = { "password": password, "vesselId": vesselId, vesselUserId: vesselUserId };
		return this.httpClient.post(this.baseURL + "resetPassword", data, { headers: this.getHeaders1() });
	}
	
	public vesselEmailExist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "vesselEmailExist", options);
	}
	
	public imoNoValidation(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "imoNoValidation", options);
	}
	
	public pointOfContact(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "pointOfContact", options);
	}
	
	public flagstateList() {
		return this.httpClient.get(this.baseURL + "flagstateList", { headers: this.getHeaders1() });
	}
	
	public vesselDetails(data:any) {
		return this.httpClient.post(this.baseURL + "vesselDetails", data, { headers: this.getHeaders1() });
	}
	
	public updateVessel(data:any) {
		return this.httpClient.post(this.baseURL + "updateVessel", data, { headers: this.getHeaders1() });
	}
	
	public getVesselDetails(vesselId:any) {
		var params = { vesselId: vesselId }
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "vesselDetails", options);
	}
	
	public assignInventoryList(data:any) {
		return this.httpClient.post(this.baseURL + "assignInventoryList", data, { headers: this.getHeaders1() });
	}
	
	public listVesselCrews(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "listVesselCrews", options);
	}
	
	public removeSeafarerDetails(seafarerId:any) {
		var data = { seafarerId: seafarerId };
		return this.httpClient.post(this.baseURL + "removeSeafarerDetails", data, { headers: this.getHeaders1() });
	}
	
	public rankList() {
		return this.httpClient.get(this.baseURL + "rankList", { headers: this.getHeaders1() });
	}
	
	public nationalityList(search:any) {
		var params = { search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "nationalityList", options);
	}
	
	public identityDocumentList() {
		return this.httpClient.get(this.baseURL + "identityDocumentList", { headers: this.getHeaders1() });
	}
	
	public addSeafarerDetails(data:any) {
		return this.httpClient.post(this.baseURL + "addSeafarerDetails", data, { headers: this.getHeaders1() });
	}
	
	public seafarerBasicDetails(seafarerId:any) {
		var params = { seafarerId: seafarerId };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "seafarerBasicDetails", options);
	}
	
	public editSeafarerDetails(data:any) {
		return this.httpClient.post(this.baseURL + "editSeafarerDetails", data, { headers: this.getHeaders1() });
	}
	
	public fetchVesselFromImono(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchVesselFromImono", options);
	}
	
	public portList(search:any) {
		var params = { search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "portList", options);
	}
	
	public fetchCandidateFromCcid(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchCandidateFromCcid", options);
	}
	
	public symptomQuestions(data:any) {
		return this.httpClient.post(this.baseURL + "symptomQuestions", data, { headers: this.getHeaders1() });
	}
	
	public deviceList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "deviceList", options);
	}
	
	public createCustomEvent(data:any) {
		return this.httpClient.post(this.baseURL + "createCustomEvent", data, { headers: this.getHeaders1() });
	}
	
	public eventDetails(eventId:any) {
		var params = { eventId: eventId };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "eventDetails", options);
	}
	
	public userList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "listDoctors", options);
	}
	
	public caseDoctorAssign(data:any) {
		return this.httpClient.post(this.baseURL + "caseDoctorAssign", data, { headers: this.getHeaders1() });
	}
	
	public changeEventstatus(data:any) {
		return this.httpClient.post(this.baseURL + "changeEventstatus", data, { headers: this.getHeaders1() });
	}
	
	public editEventDetails(data:any) {
		return this.httpClient.post(this.baseURL + "editEventDetails", data, { headers: this.getHeaders1() });
	}
	
	public saveEventnotes(data:any) {
		return this.httpClient.post(this.baseURL + "saveEventnotes", data, { headers: this.getHeaders1() });
	}
	
	public saveDailyNotes(data:any) {
		return this.httpClient.post(this.baseURL + "saveDailyNotes", data, { headers: this.getHeaders1() });
	}
	
	public IcdcodeList() {
		return this.httpClient.get(this.baseURL + "IcdcodeList", { headers: this.getHeaders1() });
	}
	
	public fetch_coordinates(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetch_coordinates", options);
	}
	
	public humanAnatomyAll() {
		return this.httpClient.get(this.baseURL + "fetch_coordinates_all", { headers: this.getHeaders1() });
	}
	
	public update_coordinates(params:any) {
		return this.httpClient.post(this.baseURL + "update_coordinates", params, { headers: this.getHeaders1() });
	}
	
	public eventMailList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "eventMailList", options);
	}
	
	public findTransactionId(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "findTransactionId", options);
	}
	
	public sendEventDraft(data:any) {
		return this.httpClient.post(this.baseURL + "sendEventDraft", data, { headers: this.getHeaders1() });
	}
	
	public mailUnlink(data:any) {
		return this.httpClient.post(this.baseURL + "mailUnlink", data, { headers: this.getHeaders1() });
	}
	
	public eventFileList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "eventFileList", options);
	}
	
	public caseReportList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "caseReportList", options);
	}
	
	public caseFilesUpload(file:any) {
		const formData = new FormData()
		formData.append('file', file);
		return this.httpClient.post(this.baseURL + "caseFilesUpload", formData, { headers: this.getHeaders3() })
	}
	
	public caseFilesSave(data:any) {
		return this.httpClient.post(this.baseURL + "caseFilesSave", data, { headers: this.getHeaders1() });
	}
	
	public caseFilesDelete(data:any) {
		return this.httpClient.post(this.baseURL + "caseFilesDelete", data, { headers: this.getHeaders1() });
	}
	
	public saveCaseData(data:any) {
		return this.httpClient.post(this.baseURL + "saveCaseData", data, { headers: this.getHeaders1() });
	}
	
	public caseAnalysisList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "caseAnalysisList", options);
	}
	
	public exportCases(data:any) {
		return this.httpClient.post(this.baseURL + "exportCases", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'arraybuffer' });
	}
	
	public eventInVessel(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "eventInVessel", options);
	}
	
	public getCandidateAndVesselDetails(crewId:any) {
		var params = { crewId: crewId }
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "getCandidateAndVesseldetl", options);
	}
	
	public eventFromMailRequest(data:any) {
		return this.httpClient.post(this.baseURL + "eventFromMailRequest", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'arraybuffer' });
	}
	
	public userProfileList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "usertypeList", options);
	}
	
	public isUserEmailExist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "userEmailExist", options);
	}
	
	public addUser(data:any) {
		return this.httpClient.post(this.baseURL + "addDoctor", data, { headers: this.getHeaders1() });
	}
	
	public getUserDetails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "doctorDetails", options);
	}
	
	public updateUser(data:any) {
		return this.httpClient.post(this.baseURL + "editDoctorDetails", data, { headers: this.getHeaders1() });
	}
	
	public removeDoctor(doc:any) {
		var data = {
			doctorId: doc
		}
		return this.httpClient.post(this.baseURL + "removeDoctor", data, { headers: this.getHeaders1() });
	}
	
	public resetUserPassword(password:any, userId:any) {
		var data = { "password": password, "userId": userId }
		return this.httpClient.post(this.baseURL + "newUserPassword", data, { headers: this.getHeaders1() });
	}
	
	public accessRightList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "profileList", options);
	}
	
	public removeAccess(pro:any) {
		var data = {
			profileId: pro
		}
		return this.httpClient.post(this.baseURL + "removeProfile", data, { headers: this.getHeaders1() });
	}
	
	public isProfileExist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "validateProfileName", options);
	}
	
	public addAccess(data:any) {
		return this.httpClient.post(this.baseURL + "createProfile", data, { headers: this.getHeaders1() });
	}
	
	public updateProfile(data:any) {
		return this.httpClient.post(this.baseURL + "editProfile", data, { headers: this.getHeaders1() });
	}
	
	public getProfileDetails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "profileDetails", options);
	}
	
	public pointofcontactList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "pointOfContact", options);
	}
	
	public addPOC(data:any) {
		return this.httpClient.post(this.baseURL + "pointOfContact", data, { headers: this.getHeaders1() });
	}
	
	public getPOCDetails(id:any) {
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "pointOfContact/" + id, options);
	}
	
	public updatePOC(data:any, id:any) {
		return this.httpClient.put(this.baseURL + "pointOfContact/" + id, data, { headers: this.getHeaders1() });
	}
	
	public removePOC(id:any) {
		return this.httpClient.delete(this.baseURL + "pointOfContact/" + id, { headers: this.getHeaders1() });
	}
	
	public getVesselInfo(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "vesselDetails", options);
	}
	
	public getVesselCheckList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "certificateAnswersList", options);
	}
	
	public updateSummery(data:any) {
		return this.httpClient.post(this.baseURL + "saveSummaryData", data, { headers: this.getHeaders1() });
	}
	
	public inventoryFileUpload(file:any, id:any) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('id', id);
		return this.httpClient.post(this.baseURL + "inventoryFileUpload", formData, { headers: this.getHeaders3() })
	}
	
	getPolicies() {
		//return this.firestore.collection('users').snapshotChanges();
	}
	
	public chatFileUpload(file:any) {
		const formData = new FormData();
		formData.append('file', file);
		return this.httpClient.post(this.baseURL + "fileUpload", formData, { headers: this.getHeaders3() });
	}
	
	public chatFileUploadOpen(file:any, channel:any) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('channel', channel);
		return this.httpClient.post(this.baseURL + "chatFileUpload", formData);
	}
	
	
	public deleteFolderDetails(folderId:any) {
		var data = { folderId: folderId };
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.delete(this.baseURL + "deleteFolderDetails", options);
	}
	
	public checkFolderExist(data:any) {
		return this.httpClient.post(this.baseURL + "checkFolderExist", data, { headers: this.getHeaders1() });
	}
	
	public addFolderDetails(data:any) {
		return this.httpClient.post(this.baseURL + "addFolderDetails", data, { headers: this.getHeaders1() });
	}
	
	public editFolderDetails(data:any) {
		return this.httpClient.post(this.baseURL + "editFolderDetails", data, { headers: this.getHeaders1() });
	}
	
	public fetchfolderData(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchfolderData", options);
	}
	
	public caseFollowUpList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "caseFollowUpList", options);
	}
	
	public doctorDetails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "doctorDetails", options);
	}
	
	public checkUnlink(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "checkUnlink", options);
	}
	
	public getEventData(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "getEventData", options);
	}
	
	private newUnlinkedMailCount = new Subject<any>();
	
	sendUnlinkCountIncrementEvent() {
		this.newUnlinkedMailCount.next(null);
	}
	getUnlinkCountIncrementEvent(): Observable<any> {
		return this.newUnlinkedMailCount.asObservable();
	}
	
	private newUnreadMailCount = new Subject<any>();
	
	sendUnreadCountIncrementEvent() {
		this.newUnreadMailCount.next(null);
	}

	getUnreadCountIncrementEvent(): Observable<any> {
		return this.newUnreadMailCount.asObservable();
	}
	
	public addCaseMeds(event:any, medicines:any) {
		var data = { eventId: event, meds: medicines }
		return this.httpClient.post(this.baseURL + "saveCaseMedicines", data, { headers: this.getHeaders1() });
	}
	
	public addCaseVitals(event:any, vitals:any) {
		var data = { eventId: event, caseVitals: vitals }
		return this.httpClient.post(this.baseURL + "saveCaseVitals", data, { headers: this.getHeaders1() });
	}
	
	public deleteMasterMed(id:any) {
		return this.httpClient.delete(this.baseURL + "medicineDetails/" + id, { headers: this.getHeaders1() });
	}
	
	public medicineNameValidation(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "validateActiveIngredient", options);
	}
	
	public getMedicineDetails(id:any) {
		return this.httpClient.get(this.baseURL + "medicineDetails/"+id, { headers: this.getHeaders1() });
	}
	
	public createUpdateMedicines(data:any,id:any) {
		if(id==null){
			return this.httpClient.post(this.baseURL + "medicineDetails", data, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.put(this.baseURL + "medicineDetails/" + id, data, { headers: this.getHeaders1() });
		}
	}
	
	public loadQuestionMasters() {
		return this.httpClient.get(this.baseURL + "modules", { headers: this.getHeaders1() });
	}
	
	public removeQuestionModule(id:any) {
		return this.httpClient.delete(this.baseURL + "modules/" + id, { headers: this.getHeaders1() });
	}
	
	public addQuestionModule(data:any) {
		return this.httpClient.post(this.baseURL + "modules", data, { headers: this.getHeaders1() });
	}
	
	public updateQuestionModule(data:any) {
		return this.httpClient.put(this.baseURL + "modules/" + data.id, data, { headers: this.getHeaders1() });
	}
	
	public loadQuestionGroups(id:any) {
		return this.httpClient.get(this.baseURL + "groups/" + id, { headers: this.getHeaders1() });
	}
	
	public loadQuestions(id:any) {
		return this.httpClient.get(this.baseURL + "questions/" + id, { headers: this.getHeaders1() });
	}
	
	public addNewGroup(data:any) {
		return this.httpClient.post(this.baseURL + "groups", data, { headers: this.getHeaders1() });
	}
	
	public deleteGroup(id:any) {
		return this.httpClient.delete(this.baseURL + "groups/" + id, { headers: this.getHeaders1() });
	}
	
	public AddModuleNewQuestion(data:any) {
		return this.httpClient.post(this.baseURL + "questions", data, { headers: this.getHeaders1() });
	}
	
	public UpdateModuleQuestion(data:any) {
		return this.httpClient.put(this.baseURL + "questions/" + data.question_id, data, { headers: this.getHeaders1() });
	}
	
	public removeQuestion(id:any) {
		return this.httpClient.delete(this.baseURL + "questions/" + id, { headers: this.getHeaders1() });
	}
	
	private unselectBranch = new Subject<any>();
	sendUnselectBranchEvent() {
		this.unselectBranch.next(null);
	}
	getUnselectBranchEvent(): Observable<any> {
		return this.unselectBranch.asObservable();
	}
	
	public addCaseQuestions(eventId:any, questions:any) {
		var data = { eventId: eventId, questionAnswers: questions }
		return this.httpClient.post(this.baseURL + "saveCaseQnAnswers", data, { headers: this.getHeaders1() });
	}
	
	public getMailSubject(id:any, sub:any) {
		let data: any = { mail_eventId: id, subject: sub };
		return this.httpClient.post(this.baseURL + "generateSubject", data, { headers: this.getHeaders1() });
	}
	
	public getAssignedTasks() {
		return this.httpClient.get(this.baseURL + "taskAssign", { headers: this.getHeaders1() });
	}
	
	public getUserRoles() {
		return this.httpClient.get(this.baseURL + "userroleList", { headers: this.getHeaders1() });
	}
	
	public getUsersByRole(roleId:any) {
		var data = { roleId: roleId }
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "displayRoleUsers", options);
	}
	
	public taskAssign(data:any) {
		return this.httpClient.post(this.baseURL + "taskAssign", data, { headers: this.getHeaders1() });
	}
	
	public editAssignDoctor(data:any) {
		return this.httpClient.post(this.baseURL + "editAssignDoctor", data, { headers: this.getHeaders1() });
	}
	
	public removeAssignedDoctor(data:any) {
		return this.httpClient.post(this.baseURL + "removeAssignedDoctor", data, { headers: this.getHeaders1() });
	}
	
	private followUpRefresh = new Subject<any>();

	sendFollowUpRefreshEvent() {
		this.followUpRefresh.next(null);
	}

	getFollowUpRefreshEvent(): Observable<any> {
		return this.followUpRefresh.asObservable();
	}
	
	public saveQnAnsData(data:any) {
		return this.httpClient.post(this.baseURL + "saveCaseQnAnsDetails", data, { headers: this.getHeaders1() });
	}
	
	public appointmentList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "appointmentList", options);
	}
	
	public clipprofileList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "clipprofileList", options);
	}
	
	public clipDoctorList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "clipDoctorList", options);
	}
	
	public clipClinicList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "clipClinicList", options);
	}
	
	public purposeList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "purposeList", options);
	}
	
	public departmentList() {
		return this.httpClient.get(this.baseURL + "departmentList", { headers: this.getHeaders1() });
	}
	
	public subdepartmentList() {
		return this.httpClient.get(this.baseURL + "subdepartmentList", { headers: this.getHeaders1() });
	}
	
	public createAppointment(data:any) {
		return this.httpClient.post(this.baseURL + "createAppointment", data, { headers: this.getHeaders1() });
	}
	
	public imageUpload(data:any) {
		return this.httpClient.post(this.baseURL + "imageUpload", data, { headers: this.getHeaders1() });
	}
	
	public candidateImageFileUpload(file:any) {
		const formData = new FormData();
		formData.append('file', file);
		return this.httpClient.post(this.baseURL + "candidateImageFileUpload", formData, { headers: this.getHeaders3() })
	}
	
	public appointmentDetails(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "appointmentDetails", options);
	}
	
	public updateAppointment(data:any) {
		return this.httpClient.post(this.baseURL + "updateAppointment", data, { headers: this.getHeaders1() });
	}
	
	public removeAppointment(data:any) {
		return this.httpClient.post(this.baseURL + "removeAppointment", data, { headers: this.getHeaders1() });
	}
	
	public candidateReportList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "candidateReportList", options);
	}
	
	public candidateActivityDetails(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "candidateActivityDetails", options);
	}
	
	public reportUpload(file:any) {
		const formData = new FormData();
		formData.append('file', file);
		return this.httpClient.post(this.baseURL + "reportUpload", formData, { headers: this.getHeaders3() })
	}
	
	public candidateReport(data:any) {
		return this.httpClient.post(this.baseURL + "candidateReport", data, { headers: this.getHeaders1() });
	}
	
	public rebookAppointment(data:any) {
		return this.httpClient.post(this.baseURL + "rebookAppointment", data, { headers: this.getHeaders1() });
	}
	
	public listSeafarer(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "seafarer", options);
	}
	
	public getVesselList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchCompVessels", options);
	}
	
	public addSeafarer(data:any) {
		return this.httpClient.post(this.baseURL + "seafarer", data, { headers: this.getHeaders1() });
	}
	
	public getSeafarer(id:any) {
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "seafarer/" + id, options);
	}
	
	public updateSeafarer(data:any, id:any) {
		return this.httpClient.put(this.baseURL + "seafarer/" + id, data, { headers: this.getHeaders1() });
	}
	
	public removeSeafarer(id:any) {
		return this.httpClient.delete(this.baseURL + "seafarer/" + id, { headers: this.getHeaders1() });
	}
	
	public unlinkSeafarer(seafarerId:any, type:any) {
		var data = { seafarerId: seafarerId, type: type };
		
		return this.httpClient.post(this.baseURL + "unlinkSeafarer", data, { headers: this.getHeaders1() });
	}
	
	public getPOCEmails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "getPOCEmails", options);
	}
	
	public vesselsMedicine(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "vesselInventoryDetails", options);
	}
	
	public AddMedicineBatch(data:any) {
		return this.httpClient.post(this.baseURL + "AddMedicineBatch", data, { headers: this.getHeaders1() });
	}
	
	public DisplayBatchList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "DisplayBatchList", options);
	}
	
	public updateMedicine(data:any) {
		return this.httpClient.post(this.baseURL + "addPrescription", data, { headers: this.getHeaders1() });
	}
	
	public assignInventoryInVessel(data:any) {
		return this.httpClient.post(this.baseURL + "assignInventoryInVessel", data, { headers: this.getHeaders1() });
	}
	
	public getVesselMedicines(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "medicinesVessel", options);
	}
	
	public updateMinQty(data:any) {
		return this.httpClient.post(this.baseURL + "updateMedMinQty", data, { headers: this.getHeaders1() });
	}
	
	public getMailDetails(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fetchLocalSingleEmail", options);
	}
	
	public getExtensions() {
		return this.httpClient.get(this.baseURL + "getAllExt", { headers: this.getHeaders1() });
	}
	
	public extensionValidation(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "validateCallExt", options);
	}
	
	public getAgoraToken(channelName:any) {
		const formData = new FormData();
		formData.append('channelName', channelName);
		return this.httpClient.post(this.baseURL + "agora/token", formData, { headers: this.getHeaders3() });
	}
	
	public getAgoraTokenOpen(channelName:any) {
		const formData = new FormData();
		formData.append('channelName', channelName);
		return this.httpClient.post(this.baseURL + "agora/openToken", formData);
	}
	
	public userLogOut() {
		var data = {};
		return this.httpClient.post(this.baseURL + "logout", data, { headers: this.getHeaders3() });
	}
	
	getPdf(details:any) {
		var data = details;
		return this.httpClient.post(this.baseURL + "generatePrescriptionReport", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'blob' as 'json' });
	}
	
	public casePrescriptionList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "casePrescriptionList", options);
	}
	
	public doneFollowUp(data:any) {
		return this.httpClient.post(this.baseURL + "doneFollowUp/"+data.id, {}, { headers: this.getHeaders1() });
	}
	
	public deleteVesselMedicines(data:any) {
		return this.httpClient.post(this.baseURL + "removeMedicineInVessel", data, { headers: this.getHeaders1() });
	}
	
	public userApptHP(params:any) {
		return this.httpClient.post(this.baseURL + "userApptHP", params, { headers: this.getHeaders1() });
	}
	
	public getSeafarerProfile(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "userHPDetails", options);
	}
	
	public markAllMailsReplied(params:any) {
		return this.httpClient.post(this.baseURL + "makeAllMailReplied", params, { headers: this.getHeaders1() });
	}
	
	public hpRegistrationList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "healthPassportRegList", options);
	}
	
	public getCaseSpecialist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "caseSpecialistList", options);
	}
	
	public getGroups() {
		return this.httpClient.get(this.baseURL + "getGroups", { headers: this.getHeaders1() });
	}
	
	public addWellnessAppt(params:any) {
		return this.httpClient.post(this.baseURL + "addWellnessAppt", params, { headers: this.getHeaders1() });
	}
	
	public getWellnessAppt(seafarer_id:any) {
		var params = { seafarer_id: seafarer_id }
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "getWellnessAppt", options);
	}
	
	public eventsData(params:any) {
		return this.httpClient.post(this.baseURL + "eventsData", params, { headers: this.getHeaders1() });
	}
	
	public postEmergencyNote(params:any) {
		return this.httpClient.post(this.baseURL + "emergency", params, { headers: this.getHeaders1() });
	}
	
	public postGlobalEmergencyNote(params:any) {
		return this.httpClient.post(this.baseURL + "emergency-global", params, { headers: this.getHeaders1() });
	}
	
	public getCompanyCommunicationEvent(params:any) {
		return this.httpClient.get(this.baseURL + "companyCommunication/" + params, { headers: this.getHeaders1() });
	}
	
	public postCompanyCommunicationEvent(params:any) {
		return this.httpClient.post(this.baseURL + "addCompanyCommunication", params, { headers: this.getHeaders1() });
	}
	
	public getPortVisitHistory(params:any) {
		return this.httpClient.get(this.baseURL + "portVisit/" + params, { headers: this.getHeaders1() });
	}
	
	public postPortVisitHistory(params:any) {
		return this.httpClient.post(this.baseURL + "portVisit", params, { headers: this.getHeaders1() });
	}
	
	public getCountries(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "countries", options);
	}
	
	public getCountryPorts(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "ports", options);
		
	}
	
	public postCompanyCaseStatus(params:any) {
		return this.httpClient.post(this.baseURL + "companyCaseStatus", params, { headers: this.getHeaders1() });
	}
	
	public getCompanyCaseStatus(id:any) {
		return this.httpClient.get(this.baseURL + "companyCaseStatus/"+id, { headers: this.getHeaders1() });
	}
	
	
	public getFlagStates(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "listFlagstates", options);
	}
	
	
	public getFlagStateMedicineList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "flagstateMedicineList", options);
	}
	
	public mainCompanyList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "company_master_list", options);
	}
	
	public addEditMainCompany(params:any,id:any) {
		if(id){
			return this.httpClient.post(this.baseURL + "company_master/"+id, params, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "company_master", params, { headers: this.getHeaders1() });
		}
	}
	
	public removeMainCompany(id:any) {
		return this.httpClient.delete(this.baseURL + "company_master/" + id, { headers: this.getHeaders1() });
	}
	
	public getMainCompanyDetails(id:any) {
		return this.httpClient.get(this.baseURL + "company_master/" + id, { headers: this.getHeaders1() });
	}
	
	public company_user_list(params:any,compId:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "company_user_list/"+compId, options);
	}
	
	public removeMainCompanyUser(id:any) {
		return this.httpClient.delete(this.baseURL + "company_user/" + id, { headers: this.getHeaders1() });
	}
	
	public addEditMainCompanyUser(params:any,id:any) {
		if(id){
			return this.httpClient.post(this.baseURL + "company_user/"+id, params, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "company_user", params, { headers: this.getHeaders1() });
		}
	}
	
	public getMainCompanyUserDetails(id:any) {
		return this.httpClient.get(this.baseURL + "company_user/" + id, { headers: this.getHeaders1() });
	}
	
	public fleetListByCompany(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fleetList", options);
	}
	
	public updateCaseSymptoms(params:any) {
		return this.httpClient.post(this.baseURL + "symptomUpdate", params, { headers: this.getHeaders1() });
	}
	
	public updateFlagstateMedicine(data:any) {
		return this.httpClient.post(this.baseURL + "updateFlagstateMedicine", data, { headers: this.getHeaders1() });
	}
	
	public fleet_user_list(params:any,fleetId:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "fleet_user_list/"+fleetId, options);
	}
	
	public removeFleetUser(id:any) {
		return this.httpClient.delete(this.baseURL + "fleet_user/" + id, { headers: this.getHeaders1() });
	}
	
	public addEditFleetUser(params:any,id:any) {
		if(id){
			return this.httpClient.post(this.baseURL + "fleet_user/"+id, params, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "fleet_user", params, { headers: this.getHeaders1() });
		}
	}
	
	public getFleetUserDetails(id:any) {
		return this.httpClient.get(this.baseURL + "fleet_user/" + id, { headers: this.getHeaders1() });
	}
	
	public searchMail(data:any) {
		return this.httpClient.post(this.baseURL + "mail/searchMail", data, { headers: this.getHeaders1() });
	}
	
	public eventReply(data:any) {
		return this.httpClient.post(this.baseURL + "event-reply", data, { headers: this.getHeaders1() });
	}
	
	public draftEventReply(data:any) {
		return this.httpClient.post(this.baseURL + "draft-event-reply", data, { headers: this.getHeaders1() });
	}
	
	public treatmentAdvisedMaster() {
		return this.httpClient.get(this.baseURL + "treatment-advised-master", { headers: this.getHeaders1() });
	}
	
	public getEventVitals(id:any) {
		return this.httpClient.get(this.baseURL + "vitals/"+id, { headers: this.getHeaders1() });
	}
	
	public get_default_text_editor_text() {
		return this.httpClient.get("./assets/files/default_text_editor_text.json");
	}
	
	public dropdown_options(id:any) {
		return this.httpClient.get(this.baseURL + "dropdown-options/"+id, { headers: this.getHeaders1() });
	}
	
	public add_analytics(data:any) {
		return this.httpClient.post(this.baseURL + "add-analytics", data, { headers: this.getHeaders5() });
	}
	
	public add_analytics1(data:any) {
		return this.httpClient.post(this.baseURL + "add-analytics", data, { headers: this.getHeaders1() });
	}
	
	public getCrewStatus(id:any) {
		return this.httpClient.get(this.baseURL + "crew-status/"+id, { headers: this.getHeaders1() });
	}
	
	public getCrewWorkStatus(id:any) {
		return this.httpClient.get(this.baseURL + "crew-work-status/"+id, { headers: this.getHeaders1() });
	}
	
	public getTreatmentAdvised(id:any) {
		return this.httpClient.get(this.baseURL + "treatment-advised/"+id, { headers: this.getHeaders1() });
	}
	
	public getFollowUp(id:any) {
		return this.httpClient.get(this.baseURL + "follow-up/"+id, { headers: this.getHeaders1() });
	}
	
	public getNextFollowUp(id:any) {
		return this.httpClient.get(this.baseURL + "next-follow-up/"+id, { headers: this.getHeaders1() });
	}
	
	public getCommMode(id:any) {
		return this.httpClient.get(this.baseURL + "communication-mode/"+id, { headers: this.getHeaders1() });
	}
	
	public getSpecialistConsulted(id:any) {
		return this.httpClient.get(this.baseURL + "specialist-selected/"+id, { headers: this.getHeaders1() });
	}
	
	public getPrecautionAdvised(id:any) {
		return this.httpClient.get(this.baseURL + "precaution-advised/"+id, { headers: this.getHeaders1() });
	}
	
	public getReviewerNotes(id:any) {
		return this.httpClient.get(this.baseURL + "reviewers-notes/"+id, { headers: this.getHeaders1() });
	}
	
	public getDoctorNotes(id:any) {
		return this.httpClient.get(this.baseURL + "doctor-notes/"+id, { headers: this.getHeaders1() });
	}
	
	public getSpecialistSelected(id:any) {
		return this.httpClient.get(this.baseURL + "specialist-selected/"+id, { headers: this.getHeaders1() });
	}
	
	public getCandidateSymptoms(id:any) {
		return this.httpClient.get(this.baseURL + "candidate-symptoms/"+id, { headers: this.getHeaders1() });
	}
	
	public getCandidateSymptomsHistory(id:any) {
		return this.httpClient.get(this.baseURL + "candidate-symptoms-history/"+id, { headers: this.getHeaders1() });
	}
	
	public addCandidateSymptoms(data:any) {
		return this.httpClient.post(this.baseURL + "candidate-symptoms", data, { headers: this.getHeaders1() });
	}
	
	public getAllSymptoms(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "all-symptoms", options);
	}
	
	public doneReviewerNote(id:any) {
		return this.httpClient.post(this.baseURL + "confirm-reviewers-notes/"+id, {reviewers_notes_completed:true}, { headers: this.getHeaders1() });
	}
	
	public saveReport(data:any) {
		return this.httpClient.post(this.baseURL + "report", data, { headers: this.getHeaders1() });
	}
	
	public getReport(id:any) {
		return this.httpClient.get(this.baseURL + "report/"+id, { headers: this.getHeaders1() });
	}
	
	public saveReport1(data:any,eventId:any) {
		return this.httpClient.post(this.baseURL + "repatriation-report/"+eventId, data, { headers: this.getHeaders1() });
	}
	
	public getReport1(id:any) {
		return this.httpClient.get(this.baseURL + "repatriation-report/"+id, { headers: this.getHeaders1() });
	}
	
	public getAllSpecialists() {
		return this.httpClient.get(this.baseURL + "specialists", { headers: this.getHeaders1() });
	}
	
	public getAllHandovers() {
		return this.httpClient.get(this.baseURL + "handover", { headers: this.getHeaders1() });
	}
	
	public createHandover(data:any) {
		return this.httpClient.post(this.baseURL + "handover", data, { headers: this.getHeaders1() });
	}
	
	public updateHandover(data:any,id:any) {
		return this.httpClient.put(this.baseURL + "handover/"+id, data, { headers: this.getHeaders1() });
	}
	
	public deleteHandover(id:any) {
		return this.httpClient.delete(this.baseURL + "handover/" + id, { headers: this.getHeaders1() });
	}
	
	public sendHandover() {
		return this.httpClient.post(this.baseURL + "send-handover", {}, { headers: this.getHeaders1() });
	}
	
	public deleteAnalytics(id:any) {
		return this.httpClient.delete(this.baseURL + "analytics/" + id, { headers: this.getHeaders1() });
	}
	
	public mandatoryCheck(id:any) {
		return this.httpClient.get(this.baseURL + "mandatory-check/"+id, { headers: this.getHeaders1() });
	}
	
	public getRating(id:any) {
		return this.httpClient.get(this.baseURL + "event-rating/"+id, { headers: this.getHeaders1() });
	}
	
	public setRating(id:any,rating:any) {
		return this.httpClient.post(this.baseURL + "event-rating/"+id, {rating:rating}, { headers: this.getHeaders1() });
	}
	
	public getTagSuggestions() {
		return this.httpClient.get(this.baseURL + "tags", { headers: this.getHeaders1() });
	}
	
	public fetchMedicineTags() {
		return this.httpClient.get(this.baseURL + "sub-symptom-tags", { headers: this.getHeaders1() });
	}
	
	public fetchEventMedicineTags(id:any) {
		return this.httpClient.get(this.baseURL + "event-sub-symptom-tags/"+id, { headers: this.getHeaders1() });
	}
	
	public saveEventMedicineTags(subSymptoms:any,eventId:any) {
		return this.httpClient.put(this.baseURL + "sub-symptoms/"+eventId, {sub_symptoms:subSymptoms}, { headers: this.getHeaders1() });
	}
	
	public getMailTagSuggestions() {
		return this.httpClient.get(this.baseURL + "mails/tags", { headers: this.getHeaders1() });
	}
	
	public getEventTags(EventId:any) {
		return this.httpClient.get(this.baseURL + "tags/"+EventId, { headers: this.getHeaders1() });
	}
	
	public getMailTags(mailId:any) {
		return this.httpClient.get(this.baseURL + "mails/tags/"+mailId, { headers: this.getHeaders1() });
	}
	
	public saveEventTags(id:any,tags:any) {
		return this.httpClient.post(this.baseURL + "tags/"+id, {tags:tags}, { headers: this.getHeaders1() });
	}
	
	public saveMailTags(id:any,tags:any) {
		return this.httpClient.post(this.baseURL + "mails/tags/"+id, {tags:tags}, { headers: this.getHeaders1() });
	}
	
	public markForClosure(id:any,value:any) {
		return this.httpClient.post(this.baseURL + "events/mark-for-closure/"+id+"/value/"+value, {}, { headers: this.getHeaders1() });
	}
	
	public getMultipleMailDetails(mailIds:any): Observable<any[]>{
		let responses:Observable<any>[] = [];
		for (let i = 0; i < mailIds.length; i++) {
			let params = {id:mailIds[i]};
			const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
			let response = this.httpClient.get(this.baseURL + "fetchLocalSingleEmail", options);
			responses.push(response);
		}
		return forkJoin(responses);
	}
	
	public getTemplateList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "template", options);
	}
	
	public deleteTemplate(id:any) {
		return this.httpClient.delete(this.baseURL + "template/" + id, { headers: this.getHeaders1() });
	}
	
	public getTemplateDetails(id:any) {
		return this.httpClient.get(this.baseURL + "template/" + id, { headers: this.getHeaders1() });
	}
	
	public createUpdateTemplate(data:any,id:any) {
		if(id){
			return this.httpClient.put(this.baseURL + "template/"+id, data, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "template", data, { headers: this.getHeaders1() });
		}
	}
	
	public getRankList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "rank", options);
	}
	
	public deleteRank(id:any) {
		return this.httpClient.delete(this.baseURL + "rank/" + id, { headers: this.getHeaders1() });
	}
	
	public deleteMultipleRanks(id:any) {
		return this.httpClient.delete(this.baseURL + "rank/" + id, { headers: this.getHeaders1() });
	}
	
	public getRankDetails(id:any) {
		return this.httpClient.get(this.baseURL + "rank/" + id, { headers: this.getHeaders1() });
	}
	
	public createUpdateRank(data:any,id:any) {
		if(id){
			return this.httpClient.put(this.baseURL + "rank/"+id, data, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "rank", data, { headers: this.getHeaders1() });
		}
	}
	
	public getNationalityList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "nationality", options);
	}
	
	public deleteNationality(id:any) {
		return this.httpClient.delete(this.baseURL + "nationality/" + id, { headers: this.getHeaders1() });
	}
	
	public deleteMultipleNationality(id:any) {
		return this.httpClient.delete(this.baseURL + "nationality/" + id, { headers: this.getHeaders1() });
	}
	
	public getNationalityDetails(id:any) {
		return this.httpClient.get(this.baseURL + "nationality/" + id, { headers: this.getHeaders1() });
	}
	
	public createUpdateNationality(data:any,id:any) {
		if(id){
			return this.httpClient.put(this.baseURL + "nationality/"+id, data, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "nationality", data, { headers: this.getHeaders1() });
		}
	}
	
	public getMedicineSuggestion(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "medicine-suggestion", options);
	}
	
	public createUpdateShoreAssistance(data:any,id:any) {
		if(id){
			return this.httpClient.put(this.baseURL + "shore-assistance/"+id, data, { headers: this.getHeaders1() });
		}else{
			return this.httpClient.post(this.baseURL + "shore-assistance", data, { headers: this.getHeaders1() });
		}
	}
	
	public getShoreAssistance(params:any){
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.baseURL + "shore-assistance", options);
	}
	
	public deleteShoreAssistance(id:any) {
		return this.httpClient.delete(this.baseURL + "shore-assistance/" + id, { headers: this.getHeaders1() });
	}
	
	public getFollowupCount() {
		return this.httpClient.get(this.baseURL + "followup-count", { headers: this.getHeaders1() });
	}
	
	public getCountryList() {
		return this.httpClient.get(this.baseURL + "country_list", { headers: this.getHeaders1() });
	}
	
	public getPortsByCountry(countryCode:any) {
		return this.httpClient.get(this.baseURL + "port_list/"+countryCode, { headers: this.getHeaders1() });
	}
	
	public getCLIPFiles(eventId:any) {
		return this.httpClient.get(this.baseURL + "clip-files/"+eventId, { headers: this.getHeaders1() });
	}

	public signOffClip(data:any,id:any) {
		return this.httpClient.post(this.baseURL + "clip/sign-off/"+id, data, { headers: this.getHeaders1() });
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}